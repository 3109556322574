@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@400;600&display=swap');
:root {
    --color-vis: #00b4d8;
    --color-product: #e09f3e;
    --color-eng: #b23a48;
    --color-analytics: #344e41;
    --color-research: #9f86c0;
}

.circle {
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
}
.small {
    width: 8px;
    height: 8px;
}

.vis {
    background-color: var(--color-vis);
}
.product {
    background-color: var(--color-product);
}
.eng {
    background-color: var(--color-eng);
}
.analytics {
    background-color: var(--color-analytics);
}
.research {
    background-color: var(--color-research);
}

.material-symbols-outlined {
  font-size: 20px;
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24
}